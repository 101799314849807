<template>
  <div id="preview">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Member Edit</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Edit member details.</h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Firstname</h6>
                <v-text-field
                  v-model="FirstName"
                  :rules="FirstNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>MiddleName <small> (optional)</small></h6>
                <v-text-field
                  v-model="MiddleName"
                  :rules="MiddleNameRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>LastName <small> (optional)</small></h6>
                <v-text-field
                  v-model="LastName"
                  :rules="LastNameRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="12" md="12">
                <h6><span class="text-danger">*</span> Street 1</h6>
                <v-text-field
                  v-model="Street1"
                  :rules="Street1Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="8" md="8">
                <h6>Street 2 <small> (optional)</small></h6>
                <v-text-field
                  v-model="Street2"
                  :rules="Street2Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>Area <small> (optional)</small></h6>
                <v-text-field
                  v-model="Area"
                  :rules="AreaRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> State</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateCodeOptionsLoading"
                  :items="StateCodeOptions"
                  :rules="StateCodeRules"
                  v-model="StateCode"
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="getStateCodeOptions"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> District</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictCodeOptionsLoading"
                  :items="DistrictCodeOptions"
                  :rules="DistrictCodeRules"
                  v-model="DistrictCode"
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="getDistrictCodeOptions"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> City</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityCodeOptionsLoading"
                  :items="CityCodeOptions"
                  :rules="CityCodeRules"
                  v-model="CityCode"
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="getCityCodeOptions"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Pincode</h6>
                <v-text-field
                  v-model="Pincode"
                  :rules="PincodeRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Mobile No</h6>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Email Id</h6>
                <v-text-field
                  v-model="EmailId"
                  :rules="EmailIdRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" lg="4" md="4" v-if="RoleId == 2">
                <v-select
                  :reduce="(option) => option.value"
                  :items="ActiveStatusOptions"
                  :rules="ActiveStatusRules"
                  v-model="member.UserActiveStatus"
                  label="Active Status"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col> -->
              <v-col cols="12" sm="4" lg="4" md="4">
                <h6>Existing Member Image</h6>
                <v-img
                  :src="ExistingImage"
                  height="100"
                  contain
                  v-if="ExistingImage != ''"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>
                  Upload new image
                  <small> (optional)</small>
                </h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Upload new image"
                  prepend-icon="mdi-camera"
                  append-outer-icon="mdi-delete"
                  v-model="UploadedImage"
                  @click:append-outer="deleteImage"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>Uploaded Image</h6>
                <cropper
                  ref="cropper"
                  :src="NewImage"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'preview',
                  }"
                  :stencil-size="{
                    width: 300,
                    height: 300,
                  }"
                  image-restriction="stencil"
                />
                <div class="actions">
                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(true, false)"
                    >
                      <v-icon>fa fa-caret-right</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(false, true)"
                    >
                      <v-icon>fa fa-caret-up</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(90)"
                    >
                      <v-icon>fa fa-redo</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(-90)"
                    >
                      <v-icon>fa fa-undo</v-icon>
                    </v-btn>
                  </b-button-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  type="submit"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#ff7f50"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Submit
                </v-btn>
                <br />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="closePrompt"> Close </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {},
  data() {
    return {
      valid1: true,

      rows: [],
      records: [],
      member: {},

      alert: {},

      RoleId: "",

      LoadingFlag: false,
      SubmitFlag: false,

      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      MemberId: "",

      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],

      MiddleName: "",
      MiddleNameRules: [],

      LastName: "",
      LastNameRules: [],

      Street1: "",
      Street1Rules: [(v) => !!v || "Street 1 is required"],

      Street2: "",
      Street2Rules: [],

      Pincode: "",
      PincodeRules: [(v) => !!v || "Pincode is required"],

      Area: "",
      AreaRules: [],

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile no is required"],

      EmailId: "",
      EmailIdRules: [(v) => !!v || "Email Id is required"],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      ActiveStatus: "",
      ActiveStatusRules: [],
      ActiveStatusOptions: [
        { value: 0, text: "Inactive" },
        { value: 1, text: "Active" },
        { value: 2, text: "Suspended" },
      ],

      UploadedImage: "",
      ExistingImage: "",
      NewImage: "",
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    member: function () {
      console.log("watch member");
      var rows = this.member;
      var n1 = Object.keys(rows).length;
      // var n1 = this.rows.length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.FirstName = rows.FirstName;
        this.MiddleName = rows.MiddleName == null ? "" : rows.MiddleName;
        this.LastName = rows.LastName == null ? "" : rows.LastName;
        this.Street1 = rows.Street1;
        this.Street2 = rows.Street2 == null ? "" : rows.Street2;
        this.Area = rows.Area == null ? "" : rows.Area;
        this.Pincode = rows.Pincode == null ? "" : rows.Pincode;
        this.MobileNo = rows.MobileNo == "NA" ? "" : rows.MobileNo;
        this.EmailId = rows.EmailId == "NA" ? "" : rows.EmailId;

        this.ExistingImage = rows.MemberImagePath;

        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateCode = StateCode;
        this.getDistrictCodeOptions();
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        this.DistrictCode = DistrictCode;
        this.getCityCodeOptions();
        var CityCode = rows.CityId;
        console.log({ CityCode });
        this.CityCode = CityCode;
      }
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.LoadingFlag = false;
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.LoadingFlag = true;
      this.getDistrictCodeOptions();
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.LoadingFlag = false;
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.LoadingFlag = true;
      this.getCityCodeOptions();
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.LoadingFlag = false;
      this.CityCodeOptionsLoading = false;
    },
    UploadedImage: function () {
      console.log("watch UploadedImage");
      var file = this.UploadedImage;
      console.log({ file });
      if (this.NewImage != "") {
        URL.revokeObjectURL(this.NewImage);
      }
      var src = "";
      var name = file.name;
      console.log({ name });
      if (name != (null || undefined)) {
        var src = URL.createObjectURL(file);
      }

      console.log({ src });
      this.NewImage = src;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    reset() {
      console.log("reset called");
      this.$refs.form1.reset();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt");
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.ExistingImage = "";
      this.UploadedImage = "";
      this.NewImage = "";
    },
    deleteImage() {
      console.log("deleteImage called");
      this.UploadedImage = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      if (this.$refs.cropper.customImageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y);
      } else {
        this.$refs.cropper.flip(x, y);
      }
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var id = this.$route.query.id;
      id = id == (null || undefined) ? 0 : id;
      console.log({ id });
      this.MemberId = id;
      this.getRecordDetails();
      this.getStateCodeOptions();
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.LoadingFlag = true;
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.LoadingFlag = true;
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRecordDetails() {
      // add todo in store and clear all fields in dialog
      console.log("getRecordDetails called");

      var message = "";

      var MemberId = this.MemberId;
      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        this.LoadingFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;

        console.log("server_url=" + server_url + ", token=" + token);

        var upload = {
          UserInterface: 1,
          MemberId: MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        var records = [];

        var CancelToken = this.CancelToken;
        var cancel;

        var add_url = server_url + "api/members/show";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        })
          .then(function (response) {
            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            console.log("response=" + JSON.stringify(response));
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log(
              "output=" +
                output +
                ", flag=" +
                flag +
                ", records=" +
                JSON.stringify(records)
            );

            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              var member = records[0];
              console.log({ member });
              thisIns.member = member;
              // thisIns.sweetAlert("success", message);
            } else {
              thisIns.sweetAlert("error", message);
            }
          })
          .catch(function (error) {
            thisIns.LoadingFlag = false;

            console.log("error=" + error);
          });
      } else {
        console.log("some errors occured");
        message = "Member id should not be empty. ";
        this.sweetAlert("error", message);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        /*
        var MemberTypeId = this.MemberTypeId;
        console.log("MemberTypeId=" + MemberTypeId);

        // Get Title details

        var TitleId = this.TitleCode;
        var TitleIndex = this.TitleCodeOptions.map((e) => e.value).indexOf(
          TitleId
        );
        console.log("TitleId=" + TitleId + ", TitleIndex=" + TitleIndex);
        var TitleName = this.TitleCodeOptions[TitleIndex].text;
        console.log("TitleName=" + TitleName);
        */
        var htmlTxt = "";
        // htmlTxt += "<br/> Title <b> " + TitleName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.addFormData();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    addFormData() {
      console.log("addFormData called");

      var message = "";

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        var imagename = this.UploadedImage.name;
        console.log({ imagename });
        let upload = new FormData();

        upload.append("UserInterface", 1);
        upload.append("MemberId", this.MemberId);
        upload.append("FirstName", this.FirstName);
        upload.append("MiddleName", this.MiddleName);
        upload.append("LastName", this.LastName);
        upload.append("Street1", this.Street1);
        upload.append("Street2", this.Street2);
        upload.append("Area", this.Area);
        upload.append("Pincode", this.Pincode);
        upload.append("State", this.StateCode);
        upload.append("District", this.DistrictCode);
        upload.append("City", this.CityCode);
        upload.append("MobileNo", this.MobileNo);
        upload.append("EmailId", this.EmailId);

        const { canvas } = this.$refs.cropper.getResult();
        console.log({ canvas });
        if (canvas) {
          var imageUrl = "";
          canvas.toBlob((blob) => {
            imageUrl = canvas.toDataURL();
            console.log({ imageUrl });
            upload.append("ProfilePicture", blob, imagename);
            this.submitForm(upload);
          }, "image/jpeg");
        } else {
          upload.append("ProfilePicture", this.UploadedImage);
          this.submitForm(upload);
        }
      } else {
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      var message = "";

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      // validate1 = false;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        console.log({ upload });

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output);
              thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
